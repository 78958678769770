.home-page header {
    background-color: #44485a;
}
header {
    background-size: cover;
    background-position: center;
}
.blog-section {
    background-color: #44485a;
    background-size: cover;
    background-position: center;
}
.stories-page header {
    background-image: linear-gradient(rgba(5, 10, 35, 0.75), rgba(5, 10, 35, 0.75)), url(../images/gallery2.webp);
    }
    header {
        background-size: cover;
        background-position: center;
    }
    .margin-top{
        margin-top: 40px;
      }
      .margin-top-10 {
        margin-top: 20px;
      }


.header-container {
    display: flex;
    flex-direction: column;
    align-items: center; 
    text-align: center; 
   
}

.header-container p {
    margin-top: auto; 
}

.hm-margin{
    margin:10px
}