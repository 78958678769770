.stories-page header {
    background-image: linear-gradient(rgba(5, 10, 35, 0.75), rgba(5, 10, 35, 0.75)), url(../images/gallery2.webp);
    }
    header {
        background-size: cover;
        background-position: center;
    }
    .header {
        height: 60vh; 
      }
      .margin-top-20 {
        margin-top: 150px;
      }
      .margin-top{
        margin-top: 40px;
      }
      .margin-top-10 {
        margin-top: 20px;
      }
  
 