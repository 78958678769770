.contact-container {
    margin: 50px auto;
    padding: 20px;
    max-width: 600px;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.contact-container h2 {
    margin-bottom: 20px;
}

.contact-container p {
    margin-bottom: 10px;
}

.contact-container a {
    color: #007bff;
    text-decoration: none;
}

.contact-container a:hover {
    text-decoration: underline;
}
.margin-top{
    margin-top: 200px;
}