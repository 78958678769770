.footer {
	width:100%;
	background-color: #323f45;
	background-size: cover;
	min-height:250px;
	padding:10px 0px 25px 0px;
	}
	.li-items{
		margin-right: 15px;

	}
.pt2 {
	padding-top:40px ;
	margin-bottom:20px ;
	color: #ffffff;
	font-weight: 500;
	}
footer p {
	font-size:15px;
	color:#CCC;
	padding-bottom:0px;
	margin-bottom:8px;
	}
.mb10 {
	padding-bottom:0px ;
	}
.footer_ul_amrc {
	margin:0px ;
	list-style-type:none ;
	font-size:14px;
	padding:0px 0px 10px 0px ;
	}
.footer_ul_amrc li {
	padding:0px 0px 5px 0px;
	}
.footer_ul_amrc li a{
	color:#cccccc;
	font-size: 15px;
	font-weight: 400;
	}
.footer_ul_amrc li a i{
	margin-right: 5px;
}
.footer_ul_amrc li a:hover{
	color:#fff;
	text-decoration:none;
	}
.footer_ul_amrc li .media-left{
	margin-right: 10px;
}
.footer_ul_amrc li .media-left img{
	max-width: 50px;
}
.footer_ul_amrc li .media-body p{
	font-size: 14px;
	font-weight: 500;
	color: #ffffff;
}
.footer_ul_amrc li .media-body span{
	color: #cccccc;
	font-size: 14px;
	font-weight: 400;
}
.fleft {
	float:left;
	}
.padding-right {
	padding-right:10px;
	}
.footer_ul2_amrc {
	margin:0px;
	list-style-type:none;
	padding:0px;
	}
.footer_ul2_amrc li p {
	display:table;
	}
.footer_ul2_amrc li a:hover {
	text-decoration:none;
	}
.footer_ul2_amrc li i {
	margin-top:5px;
	}
.footer_ul2_amrc li a {
	color: #003865;
	font-size: 12px;
}
.bottom_border {
	border-bottom:1px solid #323f45;
	padding-bottom:20px;
	}
.foote_bottom_ul_amrc {
	list-style-type:none;
	padding:0px;
	display:table;
	margin-top: 10px;
	margin-right: auto;
	margin-bottom: 10px;
	margin-left: auto;
}
.foote_bottom_ul_amrc li {
	display:inline;
	}
.foote_bottom_ul_amrc li a {
	color:#ffffff;
	margin:0 12px;
	font-size: 16px;
	font-weight: 300;
	}
.footer-logo{
	padding: 20px 0px;
	text-align: center;
}
.social_footer_ul {
	display:table;
	margin:15px auto 0 auto;
	list-style-type:none;
	padding: 0;
	}
.social_footer_ul li {
	padding-left:20px;
	padding-top:10px;
	float:left;
	}
.social_footer_ul li a {
	color:#CCC;
	border:2px solid #CCC;
	padding:8px;
	border-radius:50%;
	}
.social_footer_ul li i {
	width:20px;
	height:20px;
	text-align:center;
	}
.social_footer_ul li a:hover {
	color: #003865;
	border:2px solid #003865;
}

.footer-social{
	margin: 0px;
	padding: 0px;
	list-style: none;
}
.footer-social li{
	display: inline-block;
}