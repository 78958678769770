.footer{
    background-color: white;
    border: none;

}
.card-img-top {
    height: 200px; /* Set the desired height for the images */
    object-fit: cover; /* Ensure the image covers the entire space */
  
}
.header {
    height: 30vh; 
   
  }
  .margin-top{
    margin-top: 5px;
  }
@media (max-width: 992px) {
    .align-middle-on-smaller-screen {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .card-img-top {
        height: 400px; /* Adjusted height for smaller screens */
    }
}
@media (max-width: 768px) {
    .card-img-top {
        height: 250px; /* Adjusted height for smaller screens */
    }
}