.about-page header {
    background-image: linear-gradient(rgba(5, 10, 35, 0.75), rgba(5, 10, 35, 0.75)), url(../images/gallery2.webp);
    }
    header {
        background-size: cover;
        background-position: center;
        height: 35vh;
    }
    .margin-top-20 {
        margin-top: 150px;
      }
      .header {
        height: 60vh; 
      }