
 
 .top-nav{
     background-color: #ffffff !important;
 }
 .navbar-brand img{
 border-radius: 20px;
 }
 .navbar-expand-lg.top-nav .navbar-nav .nav-link{
     padding: 10px 15px;
     color: #323232;
     font-size: 14px;
     font-weight: 500;
     text-transform: uppercase;
     position: relative;
 }
 .navbar-expand-lg.top-nav .navbar-nav .nav-link i{
     font-size: 14px;
     float: right;
     margin-left: 5px;
 }
 .navbar-expand-lg.top-nav .navbar-nav .nav-link:hover{
     color: #003865;
     border-radius: 4.8px;
 }
 .navbar-expand-lg.top-nav .navbar-nav .nav-link.active{
     color: #003865;
 }
 .navbar-expand-lg.top-nav .navbar-nav .nav-link.active:before{
     content: "";
     position: absolute;
     width: 40px;
     background: #003865;
     height: 5px;
     top: 0px;
     left: 0px;
     border-radius: 6px;
 }
 .navbar-expand-lg.top-nav .navbar-nav .nav-link.active:after{
     content: "";
     position: absolute;
     width: 40px;
     background: #003865;
     height: 5px;
     bottom: 0px;
     right: 0px;
     border-radius: 6px;
 }
 .navbar-expand-lg.top-nav .navbar-nav .nav-link:before{
     content: "";
     position: absolute;
     width: 0px;
     background: #003865;
     height: 5px;
     top: 0px;
     left: 0px;
     border-radius: 6px;
     -webkit-transition: all 500ms ease;
     -ms-transition: all 500ms ease;
     -o-transition: all 500ms ease;
     -moz-transition: all 500ms ease;
     transition: all 500ms ease;
 }
 .navbar-expand-lg.top-nav .navbar-nav .nav-link:after{
     content: "";
     position: absolute;
     width: 0px;
     background: #003865;
     height: 5px;
     bottom: 0px;
     right: 0px;
     border-radius: 6px;
     -webkit-transition: all 500ms ease;
     -ms-transition: all 500ms ease;
     -o-transition: all 500ms ease;
     -moz-transition: all 500ms ease;
     transition: all 500ms ease;
 }
 .navbar-expand-lg.top-nav .navbar-nav .nav-link:hover:before{
     width: 40px;
     -webkit-transition: all 500ms ease;
     -ms-transition: all 500ms ease;
     -o-transition: all 500ms ease;
     -moz-transition: all 500ms ease;
     transition: all 500ms ease;
 }
 .navbar-expand-lg.top-nav .navbar-nav .nav-link :hover:after{
     width: 40px;
     -webkit-transition: all 500ms ease;
     -ms-transition: all 500ms ease;
     -o-transition: all 500ms ease;
     -moz-transition: all 500ms ease;
     transition: all 500ms ease;
 }
 .nav-link-select{
   color: #0D4C92;
   font-size: 17px;
   text-transform: uppercase;
   line-height: 1;
     font-weight: 800;
   font-weight: bold;
   text-rendering: optimizeLegibility;
 }
 .nav-link-select:hover{
   text-decoration: none;
 }
 .loogo{
    height: 100px;
    width: 150px;
 }
 .fixed-menu {
   position: fixed;
   opacity: 0;
   visibility: hidden;
   left: 0px;
   top: 0px;
   min-height: auto;
   width: 100%;
   padding: 0px 0px;
   background: #ffffff;
   z-index: 0;
   -webkit-box-shadow: 0px 13px 15px -12px rgba(35,35,35,0.15);
   box-shadow: 0px 13px 15px -12px rgba(35,35,35,0.15);
   transition: all 500ms ease;
   -moz-transition: all 500ms ease;
   -webkit-transition: all 500ms ease;
   -ms-transition: all 500ms ease;
   -o-transition: all 500ms ease;
   z-index: 999;
   opacity: 1;
   visibility: visible;
   -ms-animation-name: fadeInDown;
   -moz-animation-name: fadeInDown;
   -op-animation-name: fadeInDown;
   -webkit-animation-name: fadeInDown;
   animation-name: fadeInDown;
   -ms-animation-duration: 500ms;
   -moz-animation-duration: 500ms;
   -op-animation-duration: 500ms;
   -webkit-animation-duration: 500ms;
   animation-duration: 500ms;
   -ms-animation-timing-function: linear;
   -moz-animation-timing-function: linear;
   -op-animation-timing-function: linear;
   -webkit-animation-timing-function: linear;
   animation-timing-function: linear;
   -ms-animation-iteration-count: 1;
   -moz-animation-iteration-count: 1;
   -op-animation-iteration-count: 1;
   -webkit-animation-iteration-count: 1;
   animation-iteration-count: 1;
 }
 .dropdown-toggle::after{
     display: none;
 }
 
 .navbar-expand-lg.top-nav .navbar-nav .dropdown-menu{
     margin: 0px;
     box-shadow: 3px 5px 15px rgba(0,0,0, .15);
     border: none;
     padding: 20px;
 }
 .navbar-expand-lg.top-nav .navbar-nav .dropdown-menu .dropdown-item{
     font-size: 14px;
     padding: 0px;
     margin-bottom: 15px;
     font-weight: 600;
 }
 .navbar-expand-lg.top-nav .navbar-nav .dropdown-menu .dropdown-item:last-child{
     padding: 0px;
     margin: 0px;
 }
 .navbar-expand-lg.top-nav .navbar-nav .dropdown-menu .dropdown-item:hover{
     background: none;
     color: #003865;
 }
 .top-nav .navbar-toggler{
     color: #323232;
     border-color: #323232;
 }
 .top-nav .navbar-toggler:hover{
     color: #003865;
     border-color: #003865;
 }

 @media only screen and (max-width: 480px) {
     .post-header .post-bar li{
         margin-right: 5px;
     }
 }
 

 @media only screen and (max-width: 990px){
     .our-team{ margin-bottom: 0px; }
 
 }

 
 @media (min-width:768px) and (max-width:991px){
     .navbar-expand-lg.top-nav .navbar-nav .nav-link.dropdown-toggle::after{
         position: absolute;
         top: 18px;
         right: 6px;
     }
     .top-bar{
         display: none;
     }
     .navbar-expand-lg.top-nav .navbar-nav .nav-link.active::before{
         display: none;
     }
     .navbar-expand-lg.top-nav .navbar-nav .nav-link.active::after{
         display: none;
     }
     .navbar-expand-lg.top-nav .navbar-nav .nav-link::before{
         display: none;
     }
     .navbar-expand-lg.top-nav .navbar-nav .nav-link::after{
         display: none;
     }
   
 }
 
 @media screen and (max-width:767px){
     .navbar-expand-lg.top-nav .navbar-nav .nav-link.dropdown-toggle::after{
         position: absolute;
         top: 18px;
         right: 6px;
     }
 
     .top-bar{
         display: none;
     }
     .navbar-expand-lg.top-nav .navbar-nav .nav-link.active::before{
         display: none;
     }
     .navbar-expand-lg.top-nav .navbar-nav .nav-link.active::after{
         display: none;
     }
     .navbar-expand-lg.top-nav .navbar-nav .nav-link::before{
         display: none;
     }
     .navbar-expand-lg.top-nav .navbar-nav .nav-link::after{
         display: none;
     }
 }
 
 @media (min-width:320px) and (max-width:575.98px){
     .navbar-expand-lg.top-nav .navbar-nav .nav-link.dropdown-toggle::after{
         position: absolute;
         top: 18px;
         right: 6px;
     }
     
     .top-bar{
         display: none;
     }
     .navbar-expand-lg.top-nav .navbar-nav .nav-link.active::before{
         display: none;
     }
     .navbar-expand-lg.top-nav .navbar-nav .nav-link.active::after{
         display: none;
     }
     .navbar-expand-lg.top-nav .navbar-nav .nav-link::before{
         display: none;
     }
     .navbar-expand-lg.top-nav .navbar-nav .nav-link::after{
         display: none;
     }
    }