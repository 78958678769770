.red{
    color: #8A0707;
}
.text-center p{
    color: #8A0707;
}
.row {
  display: flex;
  flex-flow: row;
}
.vision{
  margin-top: 40px;
}
.dark-background{
  background-color: grey;

}