.gallery-page header {
    background-image: linear-gradient(rgba(5, 10, 35, 0.75), rgba(5, 10, 35, 0.75)), url(../images/gallery2.webp);
    }
    header {
        background-size: cover;
        background-position: center;
    }
    .margin-top-20 {
        margin-top: 150px;
      }
      .header {
        height: 60vh; 
      }
      .carousel {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    

    .containerStyles {
        max-width: 100%;
        max-height: 100%;
        width: 90%; 
        height: 50vh; 
        object-fit: fill;
    }

@media (max-width: 768px) {
    .carousel {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

        .containerStyles {
            width: 90%; 
            height: 50vh; 
            margin-top: 40px;
        }
}
